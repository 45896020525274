@import './variables.scss';

.primeiraLiga {
    width: 100%;

    .primeiraLiga__leagueName {
        text-align: center;
    }

    .primeiraLiga__content {
        display: flex;

        .primeiraLiga__content--tables {
            display: flex;
            margin: auto;

            .tablePrimeiraLiga {

                table {
                    font-size: $font-size-xl;
                    background-color: #000000;

                    tbody {
                        tr {
                            background-color: white;

                            &.teamSelected {
                                background-color: rgb(253, 251, 225);
                            }

                            &.noTeamSelected {
                                background-color: white;
                            }


                            &.notThisTeamSelected {
                                background-color: rgb(215, 210, 210);
                            }

                            td {
                                padding: 10px;

                                &.rank {
                                    text-align: right;
                                }

                                &.team {
                                    display: flex;
                                    cursor: pointer;

                                    .teamImg {
                                        width: 30px;
                                        height: 30px;
                                        background-size: cover;
                                        margin-right: 10px;
                                    }

                                    span {
                                        line-height: 30px;
                                    }
                                }

                                &.points {}

                                &.tblButtons {

                                    button {
                                        height: 30px;

                                        &.teamGameStats {
                                            margin-right: 5px;
                                        }

                                        &.h2h {}
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .tablePlayerData {
                margin-left: 50px;

                .tablePlayerData__table {
                    table {
                        font-size: 18px;
                        background-color: #000000;

                        thead {
                            background-color: white;

                            tr {
                                th {
                                    line-height: 20px;
                                }
                            }
                        }

                        tbody {

                            tr {
                                background-color: white;
                                cursor: pointer;

                                &.playerSelected {
                                    background-color: rgb(253, 251, 225);

                                }

                                &.noPlayerSelected {
                                    background-color: white;

                                }

                                &.notThisPlayerSelected {
                                    background-color: rgb(215, 210, 210);
                                }

                                td {
                                    padding: 10px;
                                    height: 30px;

                                    &.name {}

                                    &.position {}

                                    &.value {
                                        text-align: right;
                                        font-family: monospace;
                                    }

                                    &.age {}
                                }
                            }
                        }
                    }
                }
            }

            .tablePlayerDetails {
                margin: 0px 0px 0px 50px;
                font-family: Verdana, Geneva, Tahoma, sans-serif;
                display: flex;
                align-items: center;
                flex-direction: column;
                min-height: 100vh;

                h3 {
                    margin: 10px 0;
                }

                p {
                    font-size: 14px;
                    line-height: 21px;
                }

                .cardPlayerDetails {
                    background-color: #FAF9F6;
                    border: 2px solid black;
                    border-radius: 15px;
                    box-shadow: 0px 0px 15px 0px rgb(0, 0, 0);
                    color: #000000;
                    padding: 30px 0px;
                    position: relative;
                    width: 350px;
                    max-width: 100%;
                    text-align: center;


                    .cardPlayerDetails__rating {
                        color: #231E39;
                        background-color: #FFF;
                        border: 1px solid black;
                        border-radius: 5px;
                        font-size: $font-size-xl;
                        font-weight: bold;
                        padding: 10px;
                        position: absolute;
                        top: 20px;
                        left: 20px;

                        &.rating75 {
                            background-color: #0096FF;
                        }

                        &.rating70 {
                            background-color: #7CFC00;
                        }

                        &.rating65 {
                            background-color: #FFFF00;
                        }

                        &.rating60 {
                            background-color: #FFA500;
                        }

                        &.ratinglow {
                            background-color: #FF0000;
                        }

                    }

                    .cardPlayerDetails__round {
                        border: 1px solid #000000;
                        border-radius: 50%;
                        padding: 7px;
                    }

                    .tablePlayerDetails__citizenship {
                        ul {
                            list-style-type: none;
                            margin: 0;
                            padding: 0;

                            li {
                                border: 1px solid #554890;
                                border-radius: 2px;
                                display: inline-block;
                                font-size: 12px;
                                padding: 7px;

                                &:not(:last-child) {
                                    margin-right: 5px;
                                }
                            }
                        }
                    }

                    .cardPlayerDetails__positionPrimary {
                        .cardPlayerDetails__positionPrimary--position {
                            width: fit-content;
                            margin: auto;
                            background-color: #FFF5EE;
                            border: 1px solid #000000;
                            border-radius: 3px;
                            color: #231E39;
                            font-family: Montserrat, sans-serif;
                            font-weight: 500;
                            padding: 10px 25px;
                        }
                    }

                    .cardPlayerDetails__positionOther {
                        padding: 15px;

                        ul {
                            list-style-type: none;
                            margin: 0;
                            padding: 0;

                            li {
                                background-color: #FFF5EE;
                                border: 1px solid #D3D3D3;
                                border-radius: 2px;
                                display: inline-block;
                                font-size: 12px;
                                padding: 7px;

                                &:not(:last-child) {
                                    margin-right: 5px;
                                }
                            }
                        }
                    }




                }
            }

        }
    }
}

@media (max-width: $screen-xs) {
    .primeiraLiga {
        .primeiraLiga__leagueName {}

        .primeiraLiga__content {
            .primeiraLiga__content--tables {
                overflow: auto;
                display: inline-block;

                .tablePrimeiraLiga {
                    table {
                        font-size: $font-size-xs;
                        margin: auto;

                        tbody {
                            tr {
                                &.teamSelected {}

                                &.noTeamSelected {}

                                &.notThisTeamSelected {
                                    display: none;
                                }

                                td {
                                    padding: 5px;

                                    &.rank {}

                                    &.team {
                                        display: block;
                                        text-align: center;

                                        .teamImg {
                                            margin: auto;
                                        }

                                        span {
                                            line-height: normal;
                                        }
                                    }

                                    &.points {}

                                    &.tblButtons {
                                        text-align: center;

                                        button {
                                            font-size: $font-size-xs;
                                            height: auto;
                                            width: 100%;

                                            &.teamGameStats {
                                                margin: 0px 0px 5px 0px;
                                                padding: 1px;
                                            }

                                            &.h2h {}
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .tablePlayerData {
                    margin: 25px 0px 0px 0px;

                    .tablePlayerData__table {
                        table {
                            margin: auto;
                            font-size: $font-size-xs;

                            thead {
                                tr {
                                    th {}
                                }
                            }

                            tbody {
                                tr {

                                    &.playerSelected {}

                                    &.noPlayerSelected {}

                                    &.notThisPlayerSelected {
                                        display: none;
                                    }



                                    td {
                                        padding: 5px;
                                        text-align: center;

                                        &.name {}

                                        &.position {}

                                        &.value {}

                                        &.age {}
                                    }
                                }
                            }
                        }
                    }
                }

                .tablePlayerDetails {
                    min-height: fit-content;
                    margin: 25px 0px 0px 0px;
                    font-size: $font-size-xs;
                    width: 300px;
                    height: 300px;

                    h3 {}

                    p {}

                    .cardPlayerDetails {
                        margin: auto;
                        padding: 15px 0px;
                        width: 250px;

                        .cardPlayerDetails__rating {
                            font-size: $font-size-xs;
                            padding: 5px;
                            top: 15px;
                            left: 15px;

                            &.rating75 {}

                            &.rating70 {}

                            &.rating65 {}

                            &.rating60 {}

                            &.ratinglow {}
                        }

                        .cardPlayerDetails__round {
                            width: 25%;
                        }

                        .tablePlayerDetails__citizenship {
                            font-size: $font-size-xs;

                            ul {
                                li {
                                    &:not(:last-child) {}
                                }
                            }
                        }

                        .cardPlayerDetails__positionPrimary {
                            .cardPlayerDetails__positionPrimary--position {
                                padding: 5px 10px;
                            }
                        }

                        .cardPlayerDetails__positionOther {
                            ul {
                                li {
                                    &:not(:last-child) {}
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

@media (min-width: $screen-xs) and (max-width: $screen-sm) {
    .primeiraLiga {
        .primeiraLiga__leagueName {}

        .primeiraLiga__content {
            .primeiraLiga__content--tables {
                .tablePrimeiraLiga {
                    table {
                        tbody {
                            tr {
                                &.teamSelected {}

                                &.noTeamSelected {}

                                &.notThisTeamSelected {}

                                td {
                                    &.rank {}

                                    &.team {
                                        .teamImg {}

                                        span {}
                                    }

                                    &.points {}

                                    &.tblButtons {
                                        button {
                                            &.teamGameStats {}

                                            &.h2h {}
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .tablePlayerData {
                    .tablePlayerData__table {
                        table {
                            thead {
                                tr {
                                    th {}
                                }
                            }

                            tbody {
                                tr {
                                    td {
                                        &.name {}

                                        &.position {}

                                        &.value {}

                                        &.age {}
                                    }
                                }
                            }
                        }
                    }
                }

                .tablePlayerDetails {
                    h3 {}

                    p {}

                    .cardPlayerDetails {
                        .cardPlayerDetails__rating {
                            &.rating75 {}

                            &.rating70 {}

                            &.rating65 {}

                            &.rating60 {}

                            &.ratinglow {}
                        }

                        .cardPlayerDetails__round {}

                        .tablePlayerDetails__citizenship {
                            ul {
                                li {
                                    &:not(:last-child) {}
                                }
                            }
                        }

                        .cardPlayerDetails__positionPrimary {
                            .cardPlayerDetails__positionPrimary--position {}
                        }

                        .cardPlayerDetails__positionOther {
                            ul {
                                li {
                                    &:not(:last-child) {}
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-sm) and (max-width: $screen-md) {}

@media (min-width: $screen-md) and (max-width: $screen-lg) {}

@media (min-width: $screen-lg) and (max-width: $screen-xl) {}