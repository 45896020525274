@import './variables.scss';

.TryFantasyLiveMarket {
    .TryFantasyLiveMarket__content {
        .TryFantasyLiveMarket__content--pageData {
            width: 80%;
            float: left;

            .pageData__customDropdown {

                .customDropdown {}
            }

            .pageData__playerList {
                ul {
                    li {
                        .playerList__clubName {}

                        table {
                            thead {
                                tr {
                                    th {
                                        &.name {}

                                        &.position {}

                                        &.value {}

                                        &.age {}

                                        &.btn {}
                                    }
                                }
                            }

                            tbody {
                                tr {
                                    td {
                                        &.name {}

                                        &.position {}

                                        &.value {}

                                        &.age {}

                                        &.btn {}
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .TryFantasyLiveMarket__content--pageSelection {
            width: 20%;
            float: right;

            .pageSelection {
                .pageSelection__input {
                    p {}

                    input {}
                }

                .pageSelection__selectionData {

                    .pageSelection__selectionData-budget {

                        .pageSelection__selectionData--budgetLeft {}

                        .pageSelection__selectionData--budgetUsed {}

                        .pageSelection__selectionData--playerCount {}
                    }

                    .pageSelection__selectionData--selectedPlayers {
                        table {
                            tbody {
                                .selectedPlayers__player {
                                    text-align: left;

                                    .selectedPlayers__name {}

                                    .selectedPlayers__age {}

                                    .selectedPlayers__position {}

                                    .selectedPlayers__marketValue {
                                        text-align: right;
                                    }
                                }

                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    .TryFantasyLiveMarket {
        font-size: $font-size-xs;

        .TryFantasyLiveMarket__content {
            display: flex;
            flex-direction: column;

            .TryFantasyLiveMarket__content--pageData {
                width: 100%;
                order: 2;
                margin-top: 20px;

                .pageData__customDropdown {
                    text-align: center;

                    .customDropdown {}
                }

                .pageData__playerList {
                    ul {
                        padding: 0px;

                        li {
                            width: 100%;

                            .playerList__clubName {
                                display: none;
                            }

                            table {
                                width: 100%;

                                thead {
                                    tr {
                                        th {
                                            text-align: center;

                                            &.name {}

                                            &.position {}

                                            &.value {}

                                            &.age {}

                                            &.btn {}
                                        }
                                    }
                                }

                                tbody {
                                    tr {
                                        td {
                                            padding: 5px;

                                            &.name {}

                                            &.position {
                                                font-size: $font-size-xs - 2;
                                            }

                                            &.value {}

                                            &.age {}

                                            &.btn {
                                                button {
                                                    font-size: $font-size-xs - 3;
                                                    padding: 3px 0px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .TryFantasyLiveMarket__content--pageSelection {
                width: 100%;
                order: 1;

                .pageSelection {
                    text-align: center;

                    .pageSelection__input {
                        display: inline-block;

                        p {
                            display: inline-block;
                        }

                        input {
                            display: inline-block;
                        }
                    }

                    .pageSelection__selectionData {

                        .pageSelection__selectionData-budget {
                            display: flex;

                            .pageSelection__selectionData--budgetLeft {
                                width: 33%;
                            }

                            .pageSelection__selectionData--budgetUsed {
                                width: 33%;
                            }

                            .pageSelection__selectionData--playerCount {
                                width: 33%;
                            }
                        }

                        .pageSelection__selectionData--selectedPlayers {
                            table {
                                margin: auto;

                                tbody {
                                    .selectedPlayers__player {

                                        .selectedPlayers__name {}

                                        .selectedPlayers__age {}

                                        .selectedPlayers__position {}

                                        .selectedPlayers__marketValue {
                                        }
                                    }

                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-xs) and (max-width: $screen-sm) {}

@media (min-width: $screen-sm) and (max-width: $screen-md) {}

@media (min-width: $screen-md) and (max-width: $screen-lg) {}

@media (min-width: $screen-lg) and (max-width: $screen-xl) {}