@import './variables.scss';

.teamGameStats {
    padding: 5px;

    ul {
        width: 100%;
        list-style-type: none;
        display: inline-block;
        padding: 0;

        .teamGameStats__content {
            width: 100%;
            float: left;

            li {
                width: 25%;
                float: left;

                .event {
                    width: fit-content;

                    .event__content {
                        display: flex;
                        width: fit-content;

                        .event__content--component {
                            margin: 10px 50px 10px 10px;
                            display: table;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    .teamGameStats {
        display: inline-block;
        font-size: $font-size-xs;

        ul {

            .teamGameStats__content {

                li {
                    width: 100%;

                    .event {
                        width: 100%;

                        .event__content {
                            width: 100%;

                            .event__content--component {
                                width: 100%;
                                padding: 0px !important;

                                .ContainerMatchResult {
                                    width: 90% !important;
                                    .ContainerMatchResult__content {
                                        width: 100% !important;

                                        .ContainerMatchResult__content--homeTeam {
                                            margin: 0px 10px 0px 0px !important;
                                        }

                                        .ContainerMatchResult__content--result {}

                                        .ContainerMatchResult__content--awayTeam {
                                            margin-left: 10px !important;
                                        }
                                    }
                                }

                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-xs) and (max-width: $screen-sm) {}

@media (min-width: $screen-sm) and (max-width: $screen-md) {}

@media (min-width: $screen-md) and (max-width: $screen-lg) {}

@media (min-width: $screen-lg) and (max-width: $screen-xl) {}