@import './variables.scss';

.TeamH2h {
    .TeamH2h__topInfo {
        text-align: center;
        width: 99%;

        .TeamH2h__topInfo--btnShowAll {
            margin: auto 0px;
        }
    }

    .TeamH2h__clubList {
        background-color: #ededed;
        display: flex;

        .TeamH2h__clubList--list {
            width: fit-content;
            margin: auto;

            ul {
                list-style-type: none;
                display: inline-block;
                padding: 0;
                margin: auto;

                li {
                    width: 33%;
                    float: left;

                    &:first-child {
                        opacity: 0.5;

                        .clubList__team {
                            cursor: not-allowed !important;

                            .clubList__team--img {}

                            .clubList__team--txt {}
                        }
                    }

                    .clubList {
                        padding: 15px;

                        .clubList__team {
                            margin: auto;
                            width: fit-content;
                            text-align: center;
                            cursor: pointer;
                            width: fit-content;
                            margin: auto;

                            .clubList__team--img {
                                width: 30px;
                                height: 30px;
                                background-size: cover;
                                margin: auto;
                            }

                            .clubList__team--txt {}
                        }

                        .h2hInfo {
                            text-align: center;
                            color: rgb(180, 102, 7);
                        }
                    }
                }
            }
        }
    }

}

@media (max-width: $screen-xs) {
    .TeamH2h {
        font-size: $font-size-xs;

        .TeamH2h__topInfo {

            .TeamH2h__topInfo--btnShowAll {}
        }

        .TeamH2h__clubList {

            .TeamH2h__clubList--list {

                ul {
                    li {

                        &:first-child {

                            .clubList__team {

                                .clubList__team--img {}

                                .clubList__team--txt {}
                            }
                        }

                        .clubList {
                            padding: 5px;

                            .clubList__team {

                                .clubList__team--img {}

                                .clubList__team--txt {
                                    .h2hInfo {}
                                }
                            }
                        }
                    }
                }
            }
        }

    }
}

@media (min-width: $screen-xs) and (max-width: $screen-sm) {
    .TeamH2h {
        .TeamH2h__topInfo {

            .TeamH2h__topInfo--btnShowAll {}
        }

        .TeamH2h__clubList {

            .TeamH2h__clubList--list {

                ul {

                    li {

                        &:first-child {

                            .clubList__team {

                                .clubList__team--img {}

                                .clubList__team--txt {}
                            }
                        }

                        .clubList {

                            .clubList__team {

                                .clubList__team--img {}

                                .clubList__team--txt {
                                    .h2hInfo {}
                                }
                            }
                        }
                    }
                }
            }
        }

    }
}

@media (min-width: $screen-sm) and (max-width: $screen-md) {}

@media (min-width: $screen-md) and (max-width: $screen-lg) {}

@media (min-width: $screen-lg) and (max-width: $screen-xl) {}