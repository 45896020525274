@import './variables.scss';

.homePage {
  display: flex;
  width: 100vw;
  height: 100vh;
  background-image: url('../img/football_field_h.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: #737373;
  background-origin: content-box;
  padding: 10px;
  box-sizing: border-box;

  .homePage__content {
    width: 33%;
    margin: auto;

    .homePage__content--button {
      padding: 10px;

      button {
        border-radius: 40px;
        padding: 12px 28px;
        font-size: 20px;
      }
    }
  }
}

@media (max-width: $screen-lg) {
  .homePage {
    display: flex;
    width: 100vw;
    height: 100vh;
    background-image: url('../img/football_field.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: white;
    background-origin: content-box;
    box-sizing: border-box;
    padding: 0px;

    .homePage__content {
      width: 90%;
      margin: auto;

      .homePage__content--button {
        button {
          border-radius: 40px;
          padding: 12px 28px;
          font-size: 18px;
        }
      }
    }
  }
}

@media (min-width: $screen-xs) and (max-width: $screen-sm) {}

@media (min-width: $screen-sm) and (max-width: $screen-lg) {}