@import './variables.scss';

.manageTeam {
	max-width: 100vw;

	.manageTeam__listOfClubs {
		.manageTeam__listOfClubs--title {
			margin-top: 25px;
			font-size: $font-size-lg;
			text-align: center;
			width: 100%;
		}

		ul.manageTeam__listOfClubs--club {
			padding: 25px;
			margin: 0px;
			list-style-type: none;
			display: grid;
			grid-template-columns: repeat(9, 1fr);

			li {
				margin-bottom: 15px;
				width: fit-content;
			}
		}
	}

	.manageTeam__playersList {
		display: flex;

		.manageTeam__playersList--tableOriginalClub {
			width: 40%;
		}
		.manageTeam__playersList--tablePlayersSold {
			width: 20%;
		}
		.manageTeam__playersList--tableOtherClubs {
			width: 40%;
		}
	}
}
