@import './variables.scss';

.popup-overlay {
    .popup-content {
        overflow: auto;
        height: 100%;
    }
}

@media (max-width: $screen-xs) {}

@media (min-width: $screen-xs) and (max-width: $screen-sm) {}

@media (min-width: $screen-sm) and (max-width: $screen-md) {}

@media (min-width: $screen-md) and (max-width: $screen-lg) {}

@media (min-width: $screen-lg) and (max-width: $screen-xl) {}