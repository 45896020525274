@import './variables.scss';

body {
	margin: 0;
}

.overflowAuto {
	overflow: auto;
}

@media (max-width: $screen-xs) {}

@media (min-width: $screen-xs) and (max-width: $screen-sm) {}

@media (min-width: $screen-sm) and (max-width: $screen-lg) {}